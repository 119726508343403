<template>
  <div class="top-bar" :style="style">
    <div class="top-bar__container">
      <section class="top-bar__container__section top-bar__container__section--align-start">
        <brands
          :brands="brands"
          class="top-bar__container__section__logo"
          :is-open="isBrandsOpen"
          :is-mobile="isMobile"
          :current-brand="currentClient"
          :logo="currentClient.logo"
          @toggleBrands="toggleBrands"
          @closeBrands="closeBrands"
          @updateCurrentClient="redirectAndUpdateCurrentClient"
          v-if="brands.length && currentClient"
        />
        <div class="top-bar__container__section__menu">
          <navigation
            v-for="(menu, idx) in navigation"
            :key="`navigation-${idx}`"
            :menu="menu"
            :is-mobile="isMobile"
            :current-menu="currentMenu"
            :product-line="productLine"
            @toggleMenu="toggleMenu"
          />
        </div>
      </section>
      <section class="top-bar__container__section top-bar__container__section--align-end">
        <notifications
          :notifications="notifications"
          class="top-bar__container__section__notifications"
          :is-open="isNotificationsOpen"
          :is-mobile="isMobile"
          @toggleNotifications="toggleNotifications"
          v-if="notifications.length"
        />
        <!-- <search class="top-bar__container__section__search" /> -->
        <account
          :account="account"
          class="top-bar__container__section__account"
          :is-open="isAccountOpen"
          :is-mobile="isMobile"
          :dark-theme="darkTheme"
          @toggleAccount="toggleAccount"
          @switchTheme="switchTheme"
          @logout="logout"
        />
      </section>
    </div>
  </div>
</template>

<script>
import { isMobile } from '@/utils/navigator.util'
import { mapState, mapActions } from 'vuex'
import { productColor } from '@/config/productColor.config'
import Navigation from '@/components/Navigation.vue'
import Notifications from '@/components/Notifications.vue'
// import Search from '@/components/Search.vue'
import Account from '@/components/Account.vue'
import Brands from '@/components/Brands.vue'

export default {
  name: 'TopBar',
  components: {
    Navigation,
    Notifications,
    // Search,
    Account,
    Brands,
  },
  data() {
    return {
      isMobile: isMobile(),
    }
  },
  computed: {
    ...mapState({
      notifications: state => state.user.notifications,
      brands: state => state.user.brands,
      account: state => state.user.account,
      navigation: state => state.user.navigation,
      currentMenu: state => state.backoffice.currentMenu,
      isNotificationsOpen: state => state.backoffice.isNotificationsOpen,
      isAccountOpen: state => state.backoffice.isAccountOpen,
      isBrandsOpen: state => state.backoffice.isBrandsOpen,
      currentClient: state => state.user.currentClient,
      darkTheme: state => state.backoffice.darkTheme,
    }),
    style() {
      return {
        '--product-color': productColor[this.productLine],
      }
    },
    productLine() {
      return this.$route.meta.productLine || 'none'
    },
  },
  methods: {
    ...mapActions({
      toggleMenu: 'backoffice/toggleMenu',
      toggleNotifications: 'backoffice/toggleNotifications',
      toggleAccount: 'backoffice/toggleAccount',
      toggleBrands: 'backoffice/toggleBrands',
      toggleDarkTheme: 'backoffice/toggleDarkTheme',
      closeEverything: 'backoffice/closeEverything',
      userLogout: 'user/userLogout',
      updateCurrentClient: 'user/updateCurrentClient',
    }),
    redirectAndUpdateCurrentClient(newCurrentClient) {
      this.$router.push({ name: 'Locations' }).catch(() => {})
      this.updateCurrentClient(newCurrentClient)
    },
    closeBrands() {
      this.closeEverything({ brands: true })
    },
    switchTheme() {
      this.toggleDarkTheme()
      if (this.darkTheme) {
        document.documentElement.setAttribute('data-theme', 'dark')
      } else {
        document.documentElement.removeAttribute('data-theme')
      }
    },
    logout() {
      this.toggleAccount()
      this.userLogout().then(() => this.$router.push({ name: 'SignIn' }))
    },
  },
}
</script>

<style lang="scss" scoped>
.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  transition: border-bottom $transition-duration-fast $transition-effect-default;
  z-index: 6;
  border-bottom: 6px solid var(--product-color);
  box-shadow: $topbar-box-shadow;
  background-color: $topbar-color-bg;
  width: 100%;
  height: $topbar-height;

  &__container {
    @include container;

    display: flex;
    align-items: center;
    height: 100%;

    &__section {
      display: flex;
      flex: 1;
      align-items: center;
      height: 100%;

      &--align-start {
        justify-content: flex-start;
      }

      &--align-end {
        justify-content: flex-end;
      }

      &__notifications,
      &__search {
        margin-right: $generic-button-offset;
        margin-left: calc(#{$gutter-tablet} + #{$generic-button-offset});
      }

      &__account {
        margin-left: $gutter-tablet;
      }

      &__menu {
        display: none;

        @media (min-width: $screen-md) {
          display: flex;
          align-items: center;
          margin-left: $gutter-tablet;
          height: 100%;
        }
      }
    }
  }
}
</style>
