<template>
  <div class="ui-dropdown">
    <div class="ui-dropdown__label ui-dropdown__label--full" v-if="showLabel">
      {{ decoratedDropdownLabel }}
    </div>
    <multiselect
      class="ui-dropdown__multiselect"
      :class="{
        'ui-dropdown__multiselect--no-absolute': noAbsolute,
        'ui-dropdown__multiselect--no-radius': noRadius,
        'ui-dropdown__multiselect--no-caret': !showCaret,
        'ui-dropdown__multiselect--error': error,
      }"
      :value="value"
      :options="options"
      :placeholder="decoratedPlaceholder"
      :label="label ? label : null"
      :track-by="trackBy ? trackBy : null"
      select-label=""
      selected-label=""
      deselect-label=""
      :allow-empty="false"
      @input="onInput"
      @close="onClose"
      :disabled="disabled"
      :max-height="maxHeight"
      :open-direction="openDirection"
    >
      <template v-slot:caret="{ toggle }">
        <div
          class="ui-dropdown__multiselect__caret caret backoffice-icons"
          v-show="showCaret"
          @mousedown.prevent.stop="toggle"
        >
          chevron_down
        </div>
      </template>
      <template slot="noResult">
        {{ noResultsLabel || $t('ui.dropdown.noResult') }}
      </template>
      <template slot="noOptions">
        {{ $t('ui.dropdown.noOptions') }}
      </template>
      <template slot-scope="{ option }" slot="singleLabel">
        <slot name="label" :label="option"> </slot>
      </template>
      <template slot-scope="{ option }" slot="option">
        <slot name="option" :option="option"> </slot>
      </template>
    </multiselect>

    <transition name="fade">
      <div class="ui-dropdown__helper" :class="{ 'ui-dropdown__helper--error': error }" v-if="helper || error">
        <slot name="helper">{{ helper }}</slot>
      </div>
    </transition>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { isRTL } from '@/utils/i18n.util'

export default {
  name: 'UiDropdown',
  props: {
    value: {
      type: [String, Object, Number],
      required: false,
      default: null,
    },
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    placeholder: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    trackBy: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    noAbsolute: {
      type: Boolean,
      required: false,
      default: false,
    },
    noRadius: {
      type: Boolean,
      required: false,
      default: false,
    },
    showCaret: {
      type: Boolean,
      required: false,
      default: true,
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    noResultsLabel: {
      type: String,
      required: false,
      default: '',
    },
    helper: {
      type: String,
      required: false,
      default: '',
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    dropdownLabel: {
      type: String,
      required: false,
      default: '',
    },
    maxHeight: {
      type: Number,
      required: false,
      default: 200,
    },
    openDirection: {
      type: String,
      required: false,
      default: '',
    },
  },
  components: {
    Multiselect,
  },
  computed: {
    decoratedDropdownLabel() {
      return this.addMarkWhenFieldRequired(this.dropdownLabel)
    },
    decoratedLabel() {
      return this.addMarkWhenFieldRequired(this.label)
    },
    decoratedPlaceholder() {
      return this.addMarkWhenFieldRequired(this.placeholder)
    },
  },
  methods: {
    addMarkWhenFieldRequired(text) {
      if (!text) {
        return text
      }
      if (!this.isRequired) {
        return text
      }
      return isRTL(this.$i18n.locale) ? `* ${text}` : `${text} *`
    },
    onInput(value) {
      this.$emit('input', value)
    },
    onClose(value) {
      this.$emit('close', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-dropdown {
  width: 100%;

  &__multiselect {
    &__caret {
      position: absolute;
      top: $radius-input;
      right: 16px;
      transform: translateY(-50%);
      transition: transform $transition-duration-fast ease-in-out;
      color: $dropdown-color-caret;
    }

    &.multiselect--active {
      .caret {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &__helper {
    @include input-helper;
  }
}
</style>

<style lang="scss">
.ui-dropdown {
  &__label {
    @include input-label;
  }

  &__multiselect {
    &--no-absolute {
      .multiselect {
        &__content-wrapper {
          position: relative;
        }
      }
    }

    &--no-radius {
      .multiselect {
        &__content-wrapper {
          @media (min-width: $screen-sm) {
            border-bottom-left-radius: $radius-default;
            border-bottom-right-radius: $radius-default;
          }
        }

        &__tags {
          @media (min-width: $screen-sm) {
            border-radius: $radius-default;
          }
        }
      }
    }

    &--no-caret {
      .multiselect {
        &__tags {
          padding: $dropdown-padding-no-caret;
        }
      }
    }

    &--error:not(.multiselect--active) {
      .multiselect {
        &__tags {
          border-color: map-get($generic-color-variants, 'error');
        }
      }
    }
  }
}
</style>
