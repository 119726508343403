<template>
  <div class="backoffice" @click="closeEverything({ menu: true, notifications: true, account: true, brands: true })">
    <top-bar />
    <transition name="fade">
      <router-view class="backoffice__views"> </router-view>
    </transition>
    <div class="backoffice__menu-mobile">
      <navigation
        v-for="(menu, idx) in navigation"
        :key="`navigation-${idx}`"
        :menu="menu"
        :is-mobile="isMobile"
        :current-menu="currentMenu"
        :product-line="productLine"
        @toggleMenu="toggleMenu"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isMobile } from '@/utils/navigator.util'
import TopBar from '@/components/TopBar.vue'
import Navigation from '@/components/Navigation.vue'

export default {
  name: 'Backoffice',
  components: {
    TopBar,
    Navigation,
  },
  data() {
    return {
      logo: 'logo-rossignol.svg',
      isMobile: isMobile(),
    }
  },
  mounted() {
    this.$i18n.locale = this.currentUser.locale
  },
  computed: {
    ...mapState({
      currentUser: state => state.user.currentUser,
      navigation: state => state.user.navigation,
      currentMenu: state => state.backoffice.currentMenu,
    }),
    productLine() {
      return this.$route.meta.productLine
    },
  },
  methods: {
    ...mapActions({
      toggleMenu: 'backoffice/toggleMenu',
      closeEverything: 'backoffice/closeEverything',
    }),
  },
}
</script>

<style lang="scss" scoped>
.backoffice {
  &__views {
    display: flex;
    position: absolute;
    top: $topbar-height;
    right: 0;
    bottom: $navigation-height;
    left: 0;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    @media (min-width: $screen-md) {
      bottom: 0;
    }
  }

  &__menu-mobile {
    display: flex;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    z-index: 5;
    border-top: 1px solid $navigation-color-border;
    height: $navigation-height;

    @media (min-width: $screen-md) {
      display: none;
    }
  }
}
</style>
