export const navigation = {
  data: {
    icon: 'my_data',
    items: {
      locations: {
        route: 'Locations',
      },
      locations_gmb: {
        route: 'LocationsGmb',
      },
      gmb_qa: {
        route: 'GmbQa',
      },
      groups: {
        route: 'Groups',
      },
      products: {
        route: 'Products',
      },
      contacts: {
        route: 'Contacts',
      },
      competition: {
        route: 'Competition',
      },
      leads: {
        route: 'Leads',
      },
      user_express: {
        route: 'UserExpress',
      },
    },
  },
  measure: {
    icon: 'measure',
    items: {
      radar: {
        route: 'Radar',
      },
      analytics: {
        route: 'Analytics',
      },
      reports: {
        route: 'Reports',
      },
      kpis: {
        route: 'Kpis',
      },
    },
  },
  engage: {
    icon: 'engage',
    items: {
      play_collect: {
        // route: 'PlayCollect',
        href: '/bo/main/index.php',
      },
      response_engine: {
        route: 'ResponseEngine',
      },
      qr_codes: {
        route: 'QrCodes',
      },
      gmb_qa: {
        route: 'GmbQa',
      },
    },
  },
  influence: {
    icon: 'influence',
    items: {
      gmb: {
        route: 'Accounts',
      },
      locator: {
        route: 'FrontOffices',
      },
      locatorId: {
        route: 'FrontOffice',
      },
      presence_management: {
        route: 'PresenceManagement',
      },
      review_pages: {
        route: 'ReviewPages',
      },
    },
  },
  admin: {
    icon: 'admin',
    items: {
      clients: {
        route: 'Clients',
      },
      password: {
        route: 'Password',
      },
      gmb_admin: {
        route: 'AdminGmb',
      },
    },
  },
}
