<template>
  <div class="ui-bubble" :class="[`ui-bubble--${variant}`, { 'ui-bubble--big': count > 9 }]">
    {{ notificationsNumber }}
  </div>
</template>

<script>
export default {
  name: 'UiBubble',
  props: {
    count: {
      type: Number,
      required: true,
    },
    variant: {
      type: String,
      required: false,
      default: 'red',
    },
  },
  computed: {
    notificationsNumber() {
      if (this.count > 99) {
        return '99+'
      }
      return this.count
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-bubble {
  @include bubble;
}
</style>
