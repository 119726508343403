<template>
  <div class="notifications" :class="{ 'notifications--open': isOpen }">
    <div class="notifications__wrapper">
      <ui-button
        button="secondary"
        icon="notifications"
        :icon-only="true"
        :label="$t('notification.title')"
        v-tooltip="{
          placement: 'top',
          trigger: 'hover',
          content: $t('notification.title'),
          offset: 3,
        }"
        @click.stop="toggleNotifications"
      />
      <transition name="fade">
        <ui-bubble
          :count="unreadNotifications.length"
          class="notifications__wrapper__count"
          v-if="notifications.length && unreadNotifications.length"
        />
      </transition>
    </div>
    <transition :name="isMobile ? 'slide-left' : 'tilt-up'">
      <div class="notifications__dropdown" v-if="isOpen">
        <div class="notifications__dropdown__header">
          <ui-button
            button="secondary"
            icon="previous"
            :icon-only="true"
            :label="$t('common.button.back')"
            class="notifications__dropdown__header__back"
            @click="toggleNotifications"
          />
          <div class="notifications__dropdown__header__title">
            {{ $t('notification.title') }}
          </div>
        </div>
        <ul class="notifications__dropdown__list">
          <li
            class="notifications__dropdown__list__item"
            :class="{ 'notifications__dropdown__list__item--unseen': !notification.seen }"
            v-for="(notification, idx) in notifications"
            :key="`notification-${idx}`"
          >
            <div class="notifications__dropdown__list__item__section">
              <div class="notifications__dropdown__list__item__section__color" :style="style(notification.type)"></div>
              <div class="notifications__dropdown__list__item__section__message">
                {{ notification.message }}
              </div>
            </div>
            <div class="notifications__dropdown__list__item__section">
              <div class="notifications__dropdown__list__item__section__time">
                {{ formatedTime(notification.timer) }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import { productColor } from '@/config/productColor.config'
import UiButton from '@/components/UI/Button.vue'
import UiBubble from '@/components/UI/Bubble.vue'
import { timeFromNow } from '@/utils/hours.util'

export default {
  name: 'Notifications',
  components: {
    UiButton,
    UiBubble,
  },
  props: {
    notifications: {
      type: Array,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    unreadNotifications() {
      return this.notifications.filter(n => !n.seen)
    },
  },
  methods: {
    style(productLine) {
      return {
        '--product-color': productColor[productLine],
      }
    },
    formatedTime(time) {
      return timeFromNow(time, this.$i18n.locale, true)
    },
    toggleNotifications() {
      this.$emit('toggleNotifications')
    },
  },
}
</script>

<style lang="scss" scoped>
.notifications {
  position: relative;

  &--open {
    z-index: 1;
  }

  &__wrapper {
    position: relative;

    &__count {
      position: absolute;
      top: 3px;
      right: 3px;
    }
  }

  &__dropdown {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--bg-color);

    @media (min-width: $screen-sm) {
      position: absolute;
      top: calc(100% + 6px);
      right: 0;
      bottom: initial;
      left: initial;
      z-index: 2;
      border-radius: $radius-default;
      box-shadow: 0 0 15px var(--box-shadow-color);
      min-width: 375px;
      overflow: hidden;
    }

    &__header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--border-color);
      background-color: var(--bg-color);
      padding: 0 $gutter-mobile;
      height: 56px;

      @media (min-width: $screen-sm) {
        display: none;
      }

      &__back {
        margin-right: 16px;
        margin-left: $generic-button-offset;
      }

      &__title {
        font-size: 1rem;
        font-weight: bold;
      }
    }

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;

      &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: $gutter-mobile;
        min-height: 48px;

        @media (min-width: $screen-sm) {
          padding: $gutter-mobile $gutter-tablet;
        }

        &--unseen {
          font-weight: bold;
        }

        &:hover {
          background-color: var(--bg-color-hover);
        }

        &__section {
          display: flex;
          align-items: center;

          &__color {
            margin-right: 12px;
            border-radius: 50%;
            background-color: var(--product-color);
            width: 16px;
            height: 16px;
          }

          &__message {
            @include text-ellipsis(2);

            flex: 1;
          }

          &__time {
            margin-left: $gutter-tablet;
            white-space: nowrap;
            font-size: $font-size-sm;
          }
        }
      }
    }
  }
}
</style>
