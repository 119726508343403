<template>
  <img class="ui-logo" :src="require(`@/assets/img/logo/${logo}`)" :style="{ width: width, height: height }" />
</template>

<script>
export default {
  name: 'UiLogo',
  props: {
    logo: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: false,
      default: 'auto',
    },
    height: {
      type: String,
      required: false,
      default: 'auto',
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-logo {
  display: inline-flex;
  max-width: 100%;
}
</style>
