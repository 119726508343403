<template>
  <div class="account" :class="{ 'account--open': isOpen }">
    <div
      class="account__wrapper"
      @click.stop="toggleAccount"
      v-tooltip="{
        placement: 'top',
        trigger: 'hover',
        content: $t('account.title'),
        offset: 3,
      }"
    >
      <img :src="account.avatar" v-if="account.avatar" class="account__wrapper__avatar" />
      <span class="account__wrapper__initials" v-else>
        {{ account.initials }}
      </span>
    </div>
    <transition :name="isMobile ? 'slide-left' : 'tilt-up'">
      <div class="account__dropdown" v-if="isOpen" @click.stop>
        <div class="account__dropdown__header">
          <ui-button
            button="secondary"
            icon="previous"
            :icon-only="true"
            :label="$t('common.button.back')"
            class="account__dropdown__header__back"
            @click="toggleAccount"
          />
          <div class="account__dropdown__header__title">
            {{ $t('account.title') }}
          </div>
        </div>
        <ul class="account__dropdown__list">
          <router-link
            class="account__dropdown__list__item"
            tag="a"
            :to="{ name: 'Settings' }"
            @click.native="toggleAccount"
          >
            <span class="account__dropdown__list__item__icon backoffice-icons"> settings </span>
            <div class="account__dropdown__list__item__label">
              {{ $t('account.item.settings') }}
            </div>
          </router-link>
          <li class="account__dropdown__list__item">
            <span class="account__dropdown__list__item__icon backoffice-icons"> dark_theme </span>
            <ui-switch
              class="account__dropdown__list__item__toggle"
              id="switch-dark-theme"
              :input-value="darkTheme"
              :value="darkTheme"
              @input="switchTheme"
              :reverse="true"
              align="space-between"
            >
              {{ $t('account.item.darkTheme') }}
            </ui-switch>
          </li>
          <li class="account__dropdown__list__item" @click.prevent="logout">
            <span class="account__dropdown__list__item__icon backoffice-icons"> logout </span>
            <div class="account__dropdown__list__item__label">
              {{ $t('account.item.logout') }}
            </div>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import UiSwitch from '@/components/UI/Switch.vue'
import UiButton from '@/components/UI/Button.vue'

export default {
  name: 'Account',
  components: {
    UiSwitch,
    UiButton,
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
    darkTheme: {
      type: Boolean,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    toggleAccount() {
      this.$emit('toggleAccount')
    },
    switchTheme() {
      this.$emit('switchTheme')
    },
    logout() {
      this.$emit('logout')
    },
  },
}
</script>

<style lang="scss" scoped>
.account {
  position: relative;

  &--open {
    z-index: 1;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: $account-box-shadow;
    cursor: pointer;
    width: $account-size;
    height: $account-size;

    &__avatar {
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }

    &__initials {
      color: var(--text-color);
      font-weight: bold;
    }
  }

  &__dropdown {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--bg-color);

    @media (min-width: $screen-sm) {
      position: absolute;
      top: calc(100% + 12px);
      right: 0;
      bottom: initial;
      left: initial;
      z-index: 2;
      border-radius: $radius-default;
      box-shadow: 0 0 15px var(--box-shadow-color);
      min-width: 250px;
      overflow: hidden;
    }

    &__header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--border-color);
      background-color: var(--bg-color);
      padding: 0 $gutter-mobile;
      height: 56px;

      @media (min-width: $screen-sm) {
        display: none;
      }

      &__back {
        margin-right: 16px;
        margin-left: $generic-button-offset;
      }

      &__title {
        font-size: 1rem;
        font-weight: bold;
      }
    }

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;

      &__item {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: $gutter-mobile / 2 $gutter-mobile;
        min-height: 48px;
        color: var(--text-color);

        @media (min-width: $screen-sm) {
          padding: 12px $gutter-tablet;
        }

        &:hover {
          background-color: var(--bg-color-hover);
          color: var(--text-color);
        }

        &:last-child {
          border-top: 1px solid var(--border-color);
          color: map-get($generic-color-variants, 'error');
        }

        &__icon {
          margin-right: 16px;
        }

        &__toggle {
          flex: 1;
          margin: (-$gutter-mobile) / 2 (-$gutter-mobile);
          cursor: pointer;
          padding: $gutter-mobile / 2 $gutter-mobile;
          height: 48px;

          @media (min-width: $screen-sm) {
            margin: (-$gutter-mobile) (-$gutter-tablet);
            padding: $gutter-mobile $gutter-tablet;
          }
        }
      }
    }
  }
}
</style>
