<template>
  <div class="navigation" :style="style">
    <div class="navigation__wrapper" @click.stop="toggleMenu">
      <div
        class="navigation__wrapper__menu"
        :class="{
          'navigation__wrapper__menu--active': menu.key === productLine,
          'navigation__wrapper__menu--open': currentMenu === menu.key,
        }"
      >
        <span class="navigation__wrapper__menu__icon backoffice-icons">
          {{ icon }}
        </span>
        <div class="navigation__wrapper__menu__label">
          {{ label }}
        </div>
      </div>
    </div>
    <transition :name="isMobile ? 'slide-up' : 'tilt-up'">
      <div class="navigation__dropdown" v-if="currentMenu === menu.key">
        <template v-for="(item, idx) in menuItems">
          <a
            :href="itemRoute(item)"
            target="_blank"
            class="navigation__dropdown__item"
            :key="`menu-item-${idx}`"
            v-if="isExternalLink(item) || isLinkGTRToBo(item)"
          >
            {{ itemLabel(item) }}
          </a>
          <router-link
            tag="a"
            class="navigation__dropdown__item"
            :key="`menu-item-${idx}`"
            :to="itemRoute(item)"
            v-else-if="itemRoute(item)"
          >
            {{ itemLabel(item) }}
          </router-link>
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import { navigation } from '@/config/navigation.config'
import { productColor } from '@/config/productColor.config'

export default {
  name: 'Navigation',
  props: {
    menu: {
      type: Object,
      required: true,
    },
    currentMenu: {
      type: String,
      required: true,
    },
    productLine: {
      type: String,
      required: false,
      default: '',
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    jwtToken() {
      return localStorage.getItem('jwt_token')
    },
    userToken() {
      return localStorage.getItem('user_token')
    },
    icon() {
      return navigation[this.menu.key].icon
    },
    label() {
      return this.$t(`navigation.${this.menu.key}.label`)
    },
    style() {
      return {
        '--product-color': productColor[this.menu.key],
      }
    },
    menuItems() {
      return this.menu.items.filter(item => (this.itemRoute(item) ? item : null))
    },
  },
  methods: {
    isLinkGTRToBo(item) {
      return item.gtrbo && item.url?.length > 0
    },
    isExternalLink(item) {
      return !item.gtrbo && item.url?.length > 0
    },
    itemRoute(item) {
      if (this.isLinkGTRToBo(item)) {
        return `${item.url}&jwt_token=${this.jwtToken}&usertoken=${this.userToken}`
      }
      if (this.isExternalLink(item)) {
        return item.url
      }
      return navigation[this.menu.key]?.items[item.key]?.route
        ? { name: navigation[this.menu.key].items[item.key]?.route }
        : null
    },
    itemLabel(item) {
      return this.$t(`navigation.${this.menu.key}.item.${item.key}`)
    },
    toggleMenu() {
      this.$emit('toggleMenu', this.menu.key)
    },
    boApiUrl(branch) {
      if (branch === 'master') {
        return process.env.BO_API_PROD
      } else if (branch === 'demo') {
        return process.env.BO_API_DEMO
      }
      return process.env.BO_API_PREPROD || 'https://admin.p.gtrsuite.com/'
    },
  },
}
</script>

<style lang="scss" scoped>
.navigation {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;

  @media (min-width: $screen-md) {
    position: relative;
    flex: initial;
    white-space: nowrap;
  }

  &__wrapper {
    z-index: 1;
    background-color: $navigation-color-bg;
    width: 100%;
    height: 100%;

    &__menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: background-color $transition-duration-default $transition-effect-default;
      width: 100%;
      height: 100%;
      color: $navigation-color-text;

      @media (min-width: $screen-sm) {
        flex-direction: row;
      }

      @media (min-width: $screen-md) {
        flex-direction: row;
        padding: 0 $gutter-mobile;
      }

      @media (min-width: $screen-lg) {
        flex-direction: row;
        padding: 0 $gutter-tablet;
      }

      &--active {
        color: var(--product-color);
      }

      &--open,
      &:hover {
        background-color: $button-color-bg-hover;
        color: var(--product-color);
      }

      &__icon {
        @media (min-width: $screen-sm) {
          margin-right: 8px;
        }

        @media (min-width: $screen-md) {
          display: none;
        }
      }

      &__label {
        margin-top: 3px;
        font-size: $font-size-sm;

        @media (min-width: $screen-sm) {
          margin-top: 0;
          font-size: $font-size-default;
        }
      }
    }
  }

  &__dropdown {
    position: absolute;
    right: 0;
    bottom: 100%;
    left: 0;
    border-top: 1px solid $navigation-color-border;
    background-color: $navigation-color-bg;

    @media (min-width: $screen-md) {
      top: 100%;
      right: initial;
      bottom: initial;
      z-index: 2;
      border: 0;
      border-radius: $radius-default;
      box-shadow: $navigation-box-shadow;
      min-width: 200px;
      overflow: hidden;
    }

    &__item {
      display: flex;
      align-items: center;
      transition: background-color $transition-duration-fast $transition-effect-default,
        color $transition-duration-fast $transition-effect-default;
      background-color: $navigation-color-bg;
      padding: 0 $gutter-mobile;
      height: 48px;
      text-decoration: none;
      color: var(--text-color);
      font-size: $font-size-default;

      @media (min-width: $screen-lg) {
        flex-direction: row;
        padding: 0 $spacing-lg / 2;
      }

      &:hover {
        background-color: $navigation-color-bg-hover;
        color: var(--product-color);
      }

      &.router-link-active {
        color: var(--product-color);
      }
    }
  }
}
</style>
