<template>
  <div class="brands" :class="{ 'brands--open': isOpen }">
    <div
      class="brands__wrapper"
      :class="{ 'brands__wrapper--multi': brands.length > 1 }"
      @click.stop="brands.length > 1 ? toggleBrands('dropdown-brands') : false"
    >
      <img :src="currentBrand.logo" v-if="currentBrand.logo" class="brands__wrapper__logo" />
      <ui-logo v-else logo="logo-gtr.svg" width="36px" height="36px" class="brands__wrapper__logo" />
      <span class="brands__wrapper__caret backoffice-icons" v-if="brands.length > 1"> chevron_down </span>
    </div>
    <transition :name="isMobile ? 'slide-left' : 'tilt-up'">
      <div class="brands__dropdown" v-if="isOpen" @click.stop>
        <div class="brands__dropdown__header">
          <ui-button
            button="secondary"
            icon="previous"
            :icon-only="true"
            label="Back"
            class="brands__dropdown__header__back"
            @click="toggleBrands"
          />
          <div class="brands__dropdown__header__title">
            {{ $t('brands.title') }}
          </div>
        </div>
        <ul class="brands__dropdown__list">
          <li class="brands__dropdown__list__item">
            <ui-dropdown
              id="dropdown-brands"
              :value="currentBrand"
              :options="brands"
              :no-absolute="true"
              :no-radius="true"
              :placeholder="$t('brands.dropdown.placeholder')"
              label="name"
              track-by="id"
              @input="onInput"
              @close="onClose"
            />
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import UiButton from '@/components/UI/Button.vue'
import UiLogo from '@/components/UI/Logo.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'

export default {
  name: 'Account',
  components: {
    UiButton,
    UiLogo,
    UiDropdown,
  },
  props: {
    brands: {
      type: Array,
      required: true,
    },
    currentBrand: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onInput(item) {
      this.$emit('updateCurrentClient', item)
    },
    onClose() {
      this.$emit('closeBrands')
    },
    toggleBrands(element) {
      this.$emit('toggleBrands', element)
    },
  },
}
</script>

<style lang="scss" scoped>
.brands {
  position: relative;

  &--open {
    z-index: 1;
  }

  &__wrapper {
    display: flex;
    align-items: center;

    &__logo {
      max-width: 90px;
      height: 35px;

      @media (min-width: $screen-sm) {
        max-width: 105px;
        height: 40px;
      }
    }
    &--multi {
      cursor: pointer;
    }
  }

  &__dropdown {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--bg-color);

    @media (min-width: $screen-sm) {
      position: absolute;
      top: calc(100% + 10px);
      right: initial;
      bottom: initial;
      left: 0;
      z-index: 2;
      border-radius: $radius-default;
      box-shadow: 0 0 15px var(--box-shadow-color);
      min-width: 350px;
    }

    &__header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--border-color);
      background-color: var(--bg-color);
      padding: 0 $gutter-mobile;
      height: 56px;

      @media (min-width: $screen-sm) {
        display: none;
      }

      &__back {
        margin-right: 16px;
        margin-left: $generic-button-offset;
      }

      &__title {
        font-size: 1rem;
        font-weight: bold;
      }
    }

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;

      &__item {
        display: flex;
        align-items: center;
        padding: $gutter-mobile;

        @media (min-width: $screen-sm) {
          padding: 0;
        }
      }
    }
  }
}
</style>
