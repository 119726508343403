<template>
  <label
    class="ui-switch"
    :class="{
      'ui-switch--disabled': disabled,
    }"
    :for="id"
  >
    <input
      type="checkbox"
      :id="id"
      :disabled="disabled"
      class="ui-switch__input"
      :checked="isChecked"
      @change="onChange"
    />
    <div class="ui-switch__label" :class="[`ui-switch__label--${align}`]">
      <span class="ui-switch__label__text" v-if="reverse">
        <slot></slot>
      </span>
      <span
        class="ui-switch__label__track"
        :class="{ 'ui-switch__label__track--reverse': reverse, 'ui-switch__label__track--standalone': standalone }"
      >
        <span class="ui-switch__label__track__circle"></span>
      </span>
      <span class="ui-switch__label__text" v-if="!reverse">
        <slot></slot>
      </span>
    </div>
  </label>
</template>

<script>
export default {
  name: 'UiSwitch',
  model: {
    prop: 'inputValue',
  },
  props: {
    inputValue: {
      type: [Array, Boolean, Number, String],
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    value: [Array, Boolean, Number, String],
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    reverse: {
      type: Boolean,
      required: false,
      default: false,
    },
    standalone: {
      type: Boolean,
      required: false,
      default: false,
    },
    align: {
      type: String,
      required: false,
      default: 'left',
    },
    colorSwitch: {
      type: String,
      required: false,
      default: 'left',
    },
  },
  computed: {
    isArray() {
      return Array.isArray(this.inputValue)
    },
    isChecked() {
      if (this.isArray) {
        return this.inputValue.includes(this.value)
      }
      return !!this.inputValue
    },
  },
  methods: {
    onChange(e) {
      const checked = e.target.checked
      if (this.isArray) {
        if (checked) {
          /**
           * Emitted when the checkbox is checked/unchecked.
           * @event input
           * @type {Event}
           */
          this.$emit('input', this.inputValue.concat([this.value]))
        } else {
          this.$emit(
            'input',
            this.inputValue.filter(v => v !== this.value)
          )
        }
      } else {
        this.$emit('input', checked)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-switch {
  @include input;

  display: inline-flex;

  &__label {
    display: flex;
    position: relative;
    flex: 1;
    align-items: center;
    cursor: pointer;

    &--space-between {
      justify-content: space-between;
    }

    &--right {
      text-align: right;
    }

    .ui-switch__input:disabled + & {
      cursor: default;
    }

    &__track {
      position: relative;
      transition: all $transition-duration-default $transition-effect-default;
      margin-right: 12px;
      border-radius: 50px;
      background-color: $switch-color-bg;
      width: $switch-width;
      height: $switch-height;

      &--reverse {
        margin-right: 0;
        margin-left: 12px;
      }

      &--standalone {
        margin: 0;
      }

      .ui-switch__input:checked + div > & {
        background-color: $switch-color-bg-active;
      }

      &__circle {
        position: absolute;
        top: 2px;
        bottom: 2px;
        left: 2px;
        transition: transform $transition-duration-default $transition-effect-default;
        border-radius: 50%;
        box-shadow: $switch-box-shadow rgba($generic-color-text, 0.2);
        background-color: $switch-color-bg-circle;
        width: calc(#{$switch-height} - 4px);

        .ui-switch__input:checked + div & {
          transform: translateX(100%);
        }
      }
    }

    &__text {
      flex: 1;
      transition: color $transition-duration-default $transition-effect-default;
      color: $switch-color-text;

      // .ui-switch__input:hover:not(:disabled) + div > &,
      // .ui-switch__input:focus:not(:disabled) + div > & {
      //   color: $switch-color-text-active;
      // }
    }
  }

  &__input {
    position: absolute;
    margin: -1px;
    border: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
  }
}
</style>
